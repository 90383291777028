function CouponService(CrudAPI) {
  return new CrudAPI({
    baseURL: '/api/coupon',
    key: 'coupon',
  });
}

CouponService.$inject = ['CrudAPIFactory'];

export default CouponService;
