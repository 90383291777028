import interpolatorConfig from './scripts/interpolator';

function config(
  $httpProvider,
  $interpolateProvider,
  $logProvider,
  $sceDelegateProvider,
  AnalyticsProvider,
  $windowProvider,
) {
  interpolatorConfig($interpolateProvider);

  // angucomplete prevents us from using this
  // $httpProvider.useLegacyPromiseExtensions(false);

  const isDevelopment = $windowProvider.$get().ENV === 'development';

  $logProvider.debugEnabled(isDevelopment);

  AnalyticsProvider.disableAnalytics(isDevelopment);
  AnalyticsProvider.setAccount('UA-62821402-1');
  AnalyticsProvider.trackPages(true);
}

config.$inject = [
  '$httpProvider',
  '$interpolateProvider',
  '$logProvider',
  '$sceDelegateProvider',
  'AnalyticsProvider',
  '$windowProvider',
];

export default config;
