function ReportService($http) {
  const baseUrl = '/api/report/';

  return {
    myReport(params) {
      return $http.get(`${baseUrl}me`, { params });
    },
    report(params) {
      return $http.get(baseUrl, { params });
    },
  };
}

ReportService.$inject = ['$http'];

export default ReportService;
