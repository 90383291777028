import angular from 'angular';
import { defaults, has } from 'lodash';

// TODO move these out into their more relevant apps and call $rootScope.applyAlert()
const CONTACT_US =
  'If you feel this is an error, please contact us at support@picardylearning.com to help you resolve this matter.';
const ALERT_KEYS = {
  'coupon.success': 'Your coupon was applied! Enjoy premium.',
  'coupon.error': [
    "Your coupon couldn't be applied. Perhaps it was already used?",
    CONTACT_US,
  ].join(' '),
  'classroom.success': 'You successfully joined your classroom',
  'classroom.error': [
    'Something went wrong when attempting to join the classroom.',
    CONTACT_US,
  ].join(' '),
};

/**
 * attach an inpageAlert to the $rootScope so that pages
 * can look for that and render it. FIXME this should have
 * better handling in general.
 */
function inpageAlert($rootScope, $location) {
  angular.extend($rootScope, {
    // you can always add to the supported alerts elsewhere and re-call $rootScope.applyAlert.
    alerts: defaults($rootScope.alerts || {}, ALERT_KEYS),
    applyAlert() {
      if (
        $location.search().alertKey &&
        has($rootScope.alerts, $location.search().alertKey)
      ) {
        $rootScope.inpageAlert = {
          type: $location.search().alertType || 'success',
          message: $rootScope.alerts[$location.search().alertKey],
        };
      }
    },
    closeAlert() {
      delete $rootScope.inpageAlert;
    },
  });

  $rootScope.applyAlert();
}

inpageAlert.$inject = ['$rootScope', '$location'];

export default inpageAlert;
