import { find, matches, omit, pickBy } from 'lodash';
import angular from 'angular';
import templateTypes from './exercise-template-types';

const indexIdRegex = /^\$[\d]+$/;

function ExerciseService(CrudAPI, $http) {
  return new CrudAPI(
    {
      baseURL: '/api/exercise',
      key: 'exercise',
    },
    {
      indexIdRegex,

      check(params) {
        return $http.post([this.baseURL, 'check'].join('/'), params);
      },

      getTemplates() {
        return templateTypes;
      },

      getDefaultTemplate() {
        return 'custom-mc';
      },

      isARightAnswer(exercise, answerId) {
        return !!this.parseRightAnswer(exercise)[answerId];
      },

      parseRightAnswer(exercise) {
        return angular.fromJson(exercise.rightAnswer || '{}');
      },

      updateRightAnswer(exercise, updates) {
        exercise.rightAnswer = angular.toJson(
          pickBy(
            {
              ...this.parseRightAnswer(exercise),
              ...updates,
            },
            item => !!item,
          ),
        );
      },

      hasNoAnswers(exercise) {
        return exercise.template === 'html';
      },

      cleanExercise(exercise) {
        switch (exercise.template) {
          case 'custom-mc':
          case 'multi-mc': {
            const answerObj = this.parseRightAnswer(exercise);

            // TODO move this into exercise-level validation
            angular.forEach(answerObj, (val, id) => {
              if (
                id.match(indexIdRegex) &&
                exercise.answers.length < +id.substr(1) + 1
              ) {
                // eslint-disable-line no-extra-parens
                // if this is an id index, make sure we still have that
                // many answers at least. if not, remove this key.
                delete answerObj[id];
              } else if (
                !id.match(indexIdRegex) &&
                !find(exercise.answers, matches({ _id: id }))
              ) {
                // if this is a normal index, check that it's still
                // in the answers array. delete it if not
                delete answerObj[id];
              }
            });

            exercise.rightAnswer = angular.toJson(answerObj);
            break;
          }
          case 'html':
            exercise.rightAnswer = '{}';
            break;

          default:
            break;
        }

        return omit(exercise, ['answerObj']);
      },
    },
  );
}

ExerciseService.$inject = ['CrudAPIFactory', '$http'];

export default ExerciseService;
