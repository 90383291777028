import angular from 'angular';

function MailingListController($scope, $http, SweetAlert) {
  angular.extend($scope, {
    submitText: 'Submit',
    submit() {
      $scope._sending = true;
      $scope.submitText = 'Sending...';

      $http({
        url: '/api/mail/subscribe',
        method: 'GET',
        params: $scope.params,
      })
        .then(() => {
          $scope.subscribed = true;
        })
        .catch(() => {
          SweetAlert.swal(
            'Whoops',
            'Something went wrong while trying to subscribe you to our mailing list.',
            'error',
          );
        })
        .finally(() => {
          $scope._sending = false;
        });
    },
  });
}

MailingListController.$inject = ['$scope', '$http', 'SweetAlert'];

export default MailingListController;
