function UserService(CrudAPI, $http) {
  return new CrudAPI(
    {
      baseURL: '/api/user',
      key: 'user',
    },
    {
      getMe() {
        return $http.get([this.baseURL, 'me'].join('/'));
      },
      createRefund(options) {
        return $http.post('/api/payments/refund', options);
      },
      deleteMe() {
        return $http.delete([this.baseURL, 'me'].join('/'));
      },
      unlink(userId, key) {
        return $http.post([this.baseURL, 'unlink', userId].join('/'), {
          user: { key },
        });
      },
      createPassword(userId, options) {
        return $http.post([this.baseURL, 'createPassword', userId].join('/'), {
          user: options,
        });
      },
      updatePassword(userId, options) {
        return $http.post([this.baseURL, 'updatePassword', userId].join('/'), {
          user: options,
        });
      },
      resetPassword(options) {
        return $http.post([this.baseURL, 'resetPassword'].join('/'), {
          user: options,
        });
      },
      generateResetPassword(options) {
        return $http.post([this.baseURL, 'generateResetPassword'].join('/'), {
          user: options,
        });
      },
    },
  );
}

UserService.$inject = ['CrudAPIFactory', '$http'];

export default UserService;
