import { bindAll, functions, values } from 'lodash';
import angular from 'angular';

function crudApiFactory($http) {
  function CrudAPI(options, extendedFunctions) {
    options = options || {};
    this.baseURL = options.baseURL;
    this.key = options.key;
    this.extend(extendedFunctions);
  }

  angular.extend(CrudAPI.prototype, {
    get(id, query) {
      return $http.get(`${this.baseURL}/get/${id}`, {
        params: query,
      });
    },
    save(instance) {
      const obj = {};
      const id = instance.id || instance._id;

      let url = this.baseURL;

      obj[this.key] = instance;

      if (id) {
        url += `/${id}`;
      }

      return $http.post(url, obj, {
        headers: { 'Content-Type': 'application/json' },
      });
    },
    delete(id) {
      return $http.delete([this.baseURL, id].join('/'));
    },
    find(query) {
      return $http.get(`${this.baseURL}/find`, {
        params: query,
        cache: true,
      });
    },
    list(/* sort = 'order'*/) {
      return this.find({});
    },
    validate(instance) {
      const violations = values(this.conditions).reduce((result, condition) => {
        if (!condition.fn(instance)) {
          result.push(condition.msg);
        }

        return result;
      }, []);

      if (violations.length > 0) {
        return violations;
      }
      return true;
    },
    extend(obj) {
      angular.extend(this, obj);

      if (functions(obj).length > 0) {
        bindAll(this, functions(obj));
      }
    },
  });

  // return the constructor
  return CrudAPI;
}

crudApiFactory.$inject = ['$http'];

export default crudApiFactory;
