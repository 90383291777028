import angular from 'angular';

// DON'T USE $document.addEventListener, it doesn't work.
// use $window.document.addEventListener.
function overrideSave($rootScope, $window) {
  // cowardly fail for stupid browsers
  if (!angular.isFunction($window.document.addEventListener)) {
    return;
  }

  // override the the save button to use the rootScope's mapped save function.
  $window.document.addEventListener(
    'keydown',
    e => {
      if (
        +e.keyCode === 83 &&
        ($window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) &&
        angular.isFunction($rootScope.save)
      ) {
        e.preventDefault();
        $rootScope.save();
      }
    },
    false,
  );
}

overrideSave.$inject = ['$rootScope', '$window'];

export default overrideSave;
