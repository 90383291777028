import angular from 'angular';
import interpolatorConfig from './interpolator';

/**
 * given an app name and the routes method, return a method that can be passed
 * to app.run as a config which will bind the routes you provided if the base
 * href on the page matched the app you're configuring.
 */
const NAME_REGEX = /^[\w]+$/;

function createRouterConfig(name, routes) {
  if (!angular.isString(name) || !NAME_REGEX.test(name)) {
    throw new Error('application names must be lowercase letters only');
  }

  if (!angular.isFunction(routes) && routes.length !== 1) {
    throw new Error(
      'routes must be a function which takes a single argument (the $routeProvider)',
    );
  }

  function routerConfig(
    $interpolateProvider,
    $routeProvider,
    $locationProvider,
    AnalyticsProvider,
  ) {
    const $browser = angular.injector(['ng']).get('$browser');
    const $log = angular.injector(['ng']).get('$log');

    $locationProvider.html5Mode(true);

    $log.debug('router config');
    $log.debug('name used:', name);
    $log.debug('base href:', $browser.baseHref());

    if (`/${name}/` !== $browser.baseHref()) {
      $log.debug('name/base href mismatch');
      return;
    }

    $log.info(`binding routes for ${name}`);

    interpolatorConfig($interpolateProvider);
    routes($routeProvider);
    AnalyticsProvider.ignoreFirstPageLoad(true);
    AnalyticsProvider.trackPrefix(`/${name}`);
  }

  routerConfig.$inject = [
    '$interpolateProvider',
    '$routeProvider',
    '$locationProvider',
    'AnalyticsProvider',
  ];
  return routerConfig;
}

export default createRouterConfig;
