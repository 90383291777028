function ngDropDirective() {
  return {
    restrict: 'A',
    scope: {
      ngDrop: '=',
    },
    link(scope, elem) {
      elem.bind('drop', scope.ngDrop);
    },
  };
}

export default ngDropDirective;
