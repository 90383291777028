import angular from 'angular';

import 'angular-route';
import 'angular-sanitize';
import 'angular-sweetalert';
import 'angular-fontawesome';
import 'ng-file-upload';
import 'angular-google-analytics';
import 'vide';

require('../../scss/main.scss');
require('font-awesome/css/font-awesome.css');

import * as controllers from './controllers';
import * as directives from './directives';
import * as scripts from './scripts';
import * as services from './services';
import config from './config';

export default angular
  .module('picardy.common', [
    'ngSanitize',
    'oitozero.ngSweetAlert',
    'mm.foundation',
    'picardy.fontawesome',
    'ngFileUpload',
    'angular-google-analytics',
  ])
  .config(config)
  .run(scripts.removeHash)
  .run(scripts.deleteModal)
  .run(scripts.redirectTo)
  .run(scripts.inpageAlert)
  .run(scripts.setTitle)
  .run(scripts.setupFoundation)
  .run(scripts.setupSweetAlert)
  .run(['Analytics', () => {}])
  .service('CrudAPIFactory', services.crud)
  .service('AnswerService', services.answer)
  .service('CouponService', services.coupon)
  .service('CourseService', services.course)
  .service('CurriculumService', services.curriculum)
  .service('ExerciseService', services.exercise)
  .service('LessonService', services.lesson)
  .service('MailService', services.mail)
  .service('TopicService', services.topic)
  .service('UploadService', services.upload)
  .service('UserService', services.user)
  .service('ApplicationService', services.application)
  .service('ClassroomService', services.classroom)
  .service('ProgressService', services.progress)
  .service('ReportService', services.report)
  .service('StripeService', services.stripe)
  .service('DateUtils', services.dateUtils)
  .service('percent', services.percent)
  .service('staticParams', services.staticParams)
  .directive('compareTo', directives.compareTo)
  .directive('sound', directives.sound)
  .directive('dynamic', directives.dynamic)
  .directive('ngDrop', directives.ngdrop)
  .directive('ngDrag', directives.ngdrag)
  .directive('feedback', directives.feedback)
  .controller('MailingListController', controllers.mailingList)
  .controller('RedirectToDashboardController', controllers.redirectToDashboard);
