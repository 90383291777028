function TopicService(CrudAPI, $http) {
  return new CrudAPI(
    {
      baseURL: '/api/topic',
      key: 'topic',
    },
    {
      getKeywords(query) {
        return $http.get(`${this.baseURL}/keywords?s=${query}`);
      },
    },
  );
}

TopicService.$inject = ['CrudAPIFactory', '$http'];

export default TopicService;
