import moment from 'moment';

function format(str, timestamp) {
  if (!timestamp) {
    return '';
  }

  return moment(timestamp).format(str);
}

function relative(timestamp) {
  if (!timestamp) {
    return '';
  }

  return moment(timestamp).fromNow();
}

function DateUtils() {
  return {
    format,
    relative,
    datetime: (...args) => format('LLLL', ...args),
    day: (...args) => format('LL', ...args),
  };
}

export default DateUtils;
