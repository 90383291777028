import { find } from 'lodash';

function staticParamsService($window) {
  return {
    /**
     * given a param name, return the parameter at that param.
     * @param paramName {String} the name of the param
     * @returns {String} value at that param as a string
     */
    getQueryParam(paramName) {
      const paramArr = $window.location.search.substring(1).split('&');
      const params = paramArr
        .map(item => item.split('=').map(decodeURIComponent))
        .reduce((obj, duple) => ({ ...obj, [duple[0]]: duple[1] }), {});
      return find(params, (val, key) => key === paramName);
    },
  };
}

staticParamsService.$inject = ['$window'];

export default staticParamsService;
