import $ from 'jquery';
import 'foundation-sites';
import 'foundation-sites/js/foundation/foundation.dropdown';
import 'foundation-sites/js/foundation/foundation.topbar';
import 'angular-foundation';

function setupFoundation($document) {
  $(() => $($document).foundation());
}

setupFoundation.$inject = ['$document'];

export default setupFoundation;
