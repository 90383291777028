import SM2 from 'soundmanager2';

/**
 * init SM2 and return the public API for use in our app.
 */
SM2.soundManager
  .setup({
    debugMode: true,
    url:
      'https://cdnjs.cloudflare.com/ajax/libs/soundmanager2/2.97a.20150601/swf/',
  })
  .beginDelayedInit();

export default SM2.soundManager;
