import { clone, find, isEmpty } from 'lodash';

function LessonService(CrudAPI, ExerciseService, Upload) {
  return new CrudAPI(
    {
      baseURL: '/api/lesson',
      key: 'lesson',
    },
    {
      save(lesson) {
        lesson = clone(lesson);
        delete lesson.projections;
        lesson.topic = { _id: lesson.topic._id };
        lesson.course = { _id: lesson.course._id };
        return CrudAPI.prototype.save.call(this, lesson);
      },
      import(file) {
        return Upload.upload({ file, url: `${this.baseURL}/import` });
      },
      conditions: {
        courseAndTopicRequired: {
          msg: 'Please select a course and topic for this lesson.',
          fn(lesson) {
            return !!lesson.course && lesson.topic;
          },
        },
        noEmptyAnswers: {
          msg: 'All exercises need a right answer.',
          fn(lesson) {
            return !find(
              lesson.exercises,
              exercise =>
                !ExerciseService.hasNoAnswers(exercise) &&
                isEmpty(ExerciseService.parseRightAnswer(exercise)),
            );
          },
        },
        customMCMoreThanOneSelected: {
          msg: 'Multiple choice exercises can only have one answer.',
          fn(lesson) {
            return !find(
              lesson.exercises,
              exercise =>
                exercise.template === 'custom-mc' &&
                Object.keys(ExerciseService.parseRightAnswer(exercise)).length >
                  1,
            );
          },
        },
      },
    },
  );
}

LessonService.$inject = ['CrudAPIFactory', 'ExerciseService', 'Upload'];

export default LessonService;
