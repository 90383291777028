import angular from 'angular';

function CourseService(CrudAPI, $http) {
  return new CrudAPI(
    {
      baseURL: '/api/course',
      key: 'course',
    },
    {
      getDemo() {
        return $http.get('/api/course/demo');
      },

      getRecent(options) {
        options = options || {};

        if (angular.isArray(options.users) && options.users.length > 0) {
          options.users = options.users.join(',');
        }

        return $http.get([this.baseURL, 'getRecent'].join('/'), options);
      },

      getClassroomProgress(options) {
        return $http.get(
          [
            this.baseURL,
            options.courseId,
            'progress',
            options.classroomId,
          ].join('/'),
          options,
        );
      },
    },
  );
}

CourseService.$inject = ['CrudAPIFactory', '$http'];

export default CourseService;
