import $ from 'jquery';
import angular from 'angular';
import soundManager from '../scripts/soundmanager';

const templateUrl = require('../../../partials/common/sound.html');

/** based on ng-soundmanager2 */
function soundDirective() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl,
    scope: {
      state: '=?',
      src: '@',
      href: '@',
      maxPlays: '@',
    },
    link(scope, elem) {
      let sound;

      scope.$watch('state', scope.icon);

      angular.extend(scope, {
        state: 'stop',
        playCount: 0,
        initSound() {
          sound = soundManager.createSound({
            url: scope.src || scope.href,
            volume: 50,
            autoLoad: true,
            autoPlay: false,
            onload: () => {},
            onfinish: () => scope.$apply(scope.nextState, 'stop'),
          });
        },
        nextState(force) {
          if (typeof sound === 'undefined') {
            scope.initSound();
          }

          if (!scope.maxPlays || scope.playCount < scope.maxPlays) {
            if (force && angular.isFunction(scope[force])) {
              scope[force]();
            } else if (scope.state === 'stop') {
              scope.play();
            } else if (scope.state === 'play') {
              scope.stop();
            }
          }
        },
        stop() {
          sound.stop();
          $(window).trigger('sound.stop');
          scope.state =
            scope.maxPlays && scope.playCount >= scope.maxPlays
              ? 'disabled'
              : 'stop';
        },
        play() {
          const onPlayFn = scope.stop.bind(scope);

          sound.play();
          scope.playCount++;

          $(window).trigger('sound.play');

          $(window).one('sound.play', onPlayFn);
          $(window).one('sound.stop', () => {
            $(window).off('sound.play', onPlayFn);
          });

          scope.state = 'play';
        },
        pause() {
          sound.pause();
          scope.state = 'pause';
        },
        icon() {
          const map = {
            stop: 'play',
            play: 'stop',
            disabled: 'play',
          };

          return map[scope.state];
        },
      });

      elem.bind('click', event => {
        event.preventDefault();
        scope.$apply(() => scope.nextState());
      });
    },
  };
}

export default soundDirective;
