import angular from 'angular';

function redirectToDashboard($scope, $window) {
  angular.extend($scope, {
    init() {
      $window.location.href = '/dashboard/';
    },
  });
}

redirectToDashboard.$inject = ['$scope', '$window'];

export default redirectToDashboard;
