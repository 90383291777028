import angular from 'angular';
import { find, first, has, map, matches, property } from 'lodash';

function AnswerService(CrudAPI) {
  return new CrudAPI(
    {
      baseURL: '/api/answer',
      key: 'answer',
    },
    {
      /** return HTML to be displayed as the right answer */
      formatAnswer(exercise) {
        let obj;

        switch (exercise.template) {
          case 'custom-mc': {
            obj = angular.fromJson(exercise.rightAnswer);
            const id = first(Object.keys(obj));
            const answer = find(exercise.answers, matches({ _id: id }));
            return angular.isObject(answer) ? answer.content : id;
          }
          case 'multi-mc': {
            obj = angular.fromJson(exercise.rightAnswer);
            const answers = exercise.answers.filter(a => has(obj, a._id));
            return map(answers, property('content')).join(', ');
          }
          case 'html':
          default:
            return '';
        }
      },
    },
  );
}

AnswerService.$inject = ['CrudAPIFactory'];

export default AnswerService;
