function CurriculumService(CrudAPI, $http) {
  function addTopics(curriculumId, topicIds) {
    return $http.post(`${this.baseURL}/${curriculumId}/topics`, { topicIds });
  }

  function removeTopics(curriculumId, topicIds) {
    return $http.delete(`${this.baseURL}/${curriculumId}/topics`, {
      params: { topicIds },
    });
  }

  return new CrudAPI(
    {
      baseURL: '/api/curriculum',
      key: 'curriculum',
    },
    {
      addTopic(curriculumId, topicId) {
        return addTopics.call(this, curriculumId, [topicId]);
      },
      addTopics,
      removeTopic(curriculumId, topicId) {
        return removeTopics.call(this, curriculumId, [topicId]);
      },
      removeTopics,
    },
  );
}

CurriculumService.$inject = ['CrudAPIFactory', '$http'];

export default CurriculumService;
