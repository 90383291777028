function dynamicDirective($compile) {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      dynamic: '=',
    },
    link(scope, element) {
      scope.$watch('dynamic', html => {
        element.html(html);
        $compile(element.contents())(scope);
      });
    },
  };
}

dynamicDirective.$inject = ['$compile'];

export default dynamicDirective;
