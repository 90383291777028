/**
 * simple script that returns a function to be passed to app.run()
 * which will add a 'setTitle' function to the $rootScope
 */
function createSetTitle($rootScope) {
  $rootScope.setTitle = function setTitle() {
    $rootScope.title = ['Picardy']
      .concat(Array.prototype.slice.call(arguments))
      .join(' - ');
  };
}

createSetTitle.$inject = ['$rootScope'];

export default createSetTitle;
