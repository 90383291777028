import angular from 'angular';

/**
 * running this through app.run will attach a deleteModal method to the $rootScope
 */
function deleteModal($rootScope, SweetAlert) {
  angular.extend($rootScope, {
    deleteModal(deleteFn, cancelFn, title, text) {
      SweetAlert.swal(
        {
          title: `Delete ${title}`,
          type: 'warning',
          text: text || `Are you sure you want to delete ${title}?`,
          showCancelButton: true,
          allowOutsideClick: true,
          confirmButtonText: 'Yes, delete it!',
          confirmButtonColor: '#b07c83',
          html: true,
        },
        confirmed => {
          if (confirmed) {
            return angular.isFunction(deleteFn) ? deleteFn() : true;
          }
          return angular.isFunction(cancelFn) ? cancelFn() : false;
        },
      );
    },
  });
}

deleteModal.$inject = ['$rootScope', 'SweetAlert'];

export default deleteModal;
