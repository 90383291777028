function ClassroomService(CrudAPI, $http) {
  return new CrudAPI(
    {
      baseURL: '/api/classroom',
      key: 'classroom',
    },
    {
      getMine(options) {
        return $http.get([this.baseURL, 'mine'].join('/'), options);
      },
      getMineUnended(options) {
        return $http.get([this.baseURL, 'mine', 'unended'].join('/'), options);
      },
      getMyClassroom(options) {
        return $http.get([this.baseURL, options.id].join('/'), options);
      },
      getClassroomStudents(options) {
        return $http.get(
          [this.baseURL, options.id, 'students'].join('/'),
          options,
        );
      },
      getClassroomLessons(options) {
        return $http.get(
          [this.baseURL, options.id, 'lessons'].join('/'),
          options,
        );
      },
      removeStudentFromClassroom(classroomId, studentId) {
        return $http.delete(
          [this.baseURL, classroomId, 'students', studentId].join('/'),
        );
      },
      isSlugAvailable(slug, id) {
        return $http.get([this.baseURL, 'slug'].join('/'), {
          params: { id, slug },
        });
      },
      inviteStudents(classroomId, emails) {
        return $http.post([this.baseURL, classroomId, 'invite'].join('/'), {
          emails,
        });
      },
    },
  );
}

ClassroomService.$inject = ['CrudAPIFactory', '$http'];

export default ClassroomService;
