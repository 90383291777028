// from http://odetocode.com/blogs/scott/archive/2014/10/13/confirm-password-validation-in-angularjs.aspx
export default function compareTo() {
  return {
    require: 'ngModel',
    scope: {
      otherModelValue: '=compareTo',
    },
    link(scope, element, attributes, ngModel) {
      ngModel.$validators.compareTo = modelValue =>
        modelValue === scope.otherModelValue;
      scope.$watch('otherModelValue', () => ngModel.$validate());
    },
  };
}
