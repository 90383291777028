function percentService() {
  return function percent(a, b) {
    a = parseFloat(a);
    b = parseFloat(b);
    if (isNaN(a) || isNaN(b) || a === 0 || b === 0) {
      return 0;
    }

    return +Math.round((a / b) * 10000).toFixed(2) / 100; // eslint-disable-line no-extra-parens
  };
}

export default percentService;
