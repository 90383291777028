import $script from 'scriptjs';

const STRIPE_URL = 'https://js.stripe.com/v2/';
const STRIPE_CHECKOUT_URL = 'https://checkout.stripe.com/checkout.js';

function stripeService($window, $q) {
  // given a namespace on the $window and a url,
  // load the JS asset at the URL if the value does not appear
  // at the namespace.
  function _loadIntoWindow(namespace, url) {
    return $q((resolve, reject) => {
      if (namespace in $window) {
        resolve($window[namespace]);
      } else {
        $script(url, () => resolve($window.StripeCheckout), reject);
      }
    });
  }

  return {
    // load the main Stripe API
    load: () => _loadIntoWindow('Stripe', STRIPE_URL),

    // load the Stripe Checkout API
    loadCheckout: () => _loadIntoWindow('StripeCheckout', STRIPE_CHECKOUT_URL),

    STRIPE_URL,
    STRIPE_CHECKOUT_URL,
  };
}

stripeService.$inject = ['$window', '$q'];

export default stripeService;
