function ngDragDirective() {
  return {
    restrict: 'A',
    scope: {
      ngDrag: '=',
    },
    link(scope, elem) {
      elem.bind('drag', scope.ngDrag);
    },
  };
}

export default ngDragDirective;
