const PICARDY_COLORS = {
  'picardy-gray': '#51534a',
  'picardy-peach': '#e6a65d',
  'picardy-blue': '#5b7f95',
  'picardy-rose': '#b07c83',
  'picardy-light-gray': '#97999b',
  'picardy-chalkboard-green': '#4f868e',
  'picardy-teal': '#b7cdc2',
  'picardy-yellow': '#d7c826',
};

export default PICARDY_COLORS;
