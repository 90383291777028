function UploadService(CrudAPI, Upload) {
  return new CrudAPI(
    {
      baseURL: '/api/upload',
      key: 'upload',
    },
    {
      upload(file) {
        return Upload.upload({ file, url: this.baseURL });
      },
      uploadBase64(file) {
        const r = /^data:(image\/([a-z]+));base64,(.*)/;
        const match = r.exec(file.data);

        // get rid of any unsafe filename characters
        file.name = file.name
          .replace(/[:*?"<>|]/, '_')
          .replace('/', '_')
          .replace('\\', '_');

        return Upload.http({
          url: `${this.baseURL}/base64`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            filename: `${file.name}.${match[2]}`,
            type: match[1],
            base64Data: match[3],
          },
        });
      },
    },
  );
}

UploadService.$inject = ['CrudAPIFactory', 'Upload'];

export default UploadService;
