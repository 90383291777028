import angular from 'angular';
import $ from 'jquery';

const templateUrl = require('../../../partials/common/feedback.html');

function feedbackDirective($modal, $location, MailService) {
  return {
    restrict: 'E',
    replace: true,
    templateUrl,
    link(scope, elem) {
      angular.extend(scope, {
        open() {
          const modalInstance = $modal.open({
            template: $('.feedback-modal', elem).html(),
          });

          modalInstance.result.then(message => {
            MailService.sendFeedback({
              page: $location.absUrl(),
              message,
            });
          });
        },
      });
    },
  };
}

feedbackDirective.$inject = ['$modal', '$location', 'MailService'];

export default feedbackDirective;
