import colors from './colors';
import deleteModal from './delete-modal';
import disableLocationProvider from './disable-location-provider';
import inpageAlert from './inpage-alert';
import interpolator from './interpolator';
import redirectTo from './redirect-to';
import removeHash from './remove-hash';
import routerConfig from './router-config';
import saveButton from './save-button';
import setTitle from './set-title';
import setupFoundation from './setup-foundation';
import setupSweetAlert from './setup-sweetalert';
import soundmanager from './soundmanager';

export {
  colors,
  deleteModal,
  disableLocationProvider,
  inpageAlert,
  interpolator,
  redirectTo,
  removeHash,
  routerConfig,
  saveButton,
  setTitle,
  setupFoundation,
  setupSweetAlert,
  soundmanager,
};
