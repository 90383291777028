function ApplicationService(CrudAPI, $http) {
  return new CrudAPI(
    {
      baseURL: '/api/application',
      key: 'application',
    },
    {
      _action(action, options) {
        return $http.post(
          [this.baseURL, options._id, action].join('/'),
          options,
        );
      },
      withdraw(options) {
        return this._action('withdraw', options);
      },
      accept(options) {
        return this._action('accept', options);
      },
      reject(options) {
        return this._action('reject', options);
      },
    },
  );
}

ApplicationService.$inject = ['CrudAPIFactory', '$http'];

export default ApplicationService;
