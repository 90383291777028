function ProgressService($http) {
  const baseUrl = '/api/progress/';

  function get(subUrl, classroomId, courseId) {
    return $http.get(baseUrl + subUrl, { params: { classroomId, courseId } });
  }

  return {
    getCourseCompleted: (...args) => get('course-completed', ...args),
    getCourseAttempted: (...args) => get('course-attempted', ...args),
    getLessonsCompleted: (...args) => get('lessons-completed', ...args),
    getLessonsAttempted: (...args) => get('lessons-attempted', ...args),

    report(userId, reportContext) {
      return $http.get(`${baseUrl}report`, {
        params: { userId, reportContext },
      });
    },

    reportContexts(userId) {
      return $http.get(`${baseUrl}reportContexts`, { params: { userId } });
    },
  };
}

ProgressService.$inject = ['$http'];

export default ProgressService;
