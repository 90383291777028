import angular from 'angular';

function redirectTo($rootScope, $window) {
  angular.extend($rootScope, {
    redirectTo(url = '/') {
      $window.location = url;
    },
  });
}

redirectTo.$inject = ['$rootScope', '$window'];

export default redirectTo;
