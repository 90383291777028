/**
 * remove the godawful, annoying hash that Facebook or Google OAuth add
 * to the end of our URLs.
 */
function removeHash($window, $document, $log) {
  let scroll;

  if ($window.location.hash && $window.location.hash === '#_=_') {
    $log.log('facebook hash detected');

    // FACEBOOK
    if ('history' in window) {
      $window.history.pushState('', $document.title, $window.location.pathname);
    } else {
      // Prevent scrolling by storing the page's current scroll offset
      scroll = {
        top: $document.body.scrollTop,
        left: $document.body.scrollLeft,
      };
      $window.location.hash = '';

      // Restore the scroll offset, should be flicker free
      $document.body.scrollTop = scroll.top;
      $document.body.scrollLeft = scroll.left;
    }
  } else if ($window.location.href.substr(-1) === '#') {
    $log.log('google hash detected');

    // GOOGLE
    $window.location.href = $window.location.href.slice(
      0,
      $window.location.href.length - 1,
    );
  }
}

removeHash.$inject = ['$window', '$document', '$log'];

export default removeHash;
