function MailService($http) {
  return {
    sendFeedback(attrs) {
      return $http.post('/api/mail/feedback', attrs);
    },
    sendReportCard(attrs) {
      return $http.post('/api/reportcard/send', attrs);
    },
  };
}

MailService.$inject = ['$http'];

export default MailService;
